import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { generateToken } from '../../utility/Token';
import { reloadTransaction } from '../../stores/transaction/Transaction';
import { QRCodeToDataURLOptions } from 'qrcode';
import { toast, ToastContainer } from 'react-toastify';
import moment from 'moment';
import qrcode from 'qrcode';
import generatePayload from 'promptpay-qr';
import AddressModal from '../../components/address-modal/AddressModal';

import './PayMobilePremium.css';
import lineIcon from '../../images/other/line.svg'
import loading from '../../images/loading/loading_spinner.svg';
import promptPay from '../../images/bank/PromptPay2.png';
import uploadIcon from '../../images/icon/upload-file-white.svg';

interface ProductPrice { price: number; }

interface ProductCalculate { price: number; lifetime_of_product: number; description: String; }



const PayMobilePremium = () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const accessData = useSelector((state: NaTypes.Store) => state.authenticate);
    const productList = useSelector((state: NaTypes.Store) => state.transaction.productList);
    const transaction = useSelector((state: NaTypes.Store) => state.transaction);

    const [amount, setAmount] = useState<number>(0);
    const [amounts, setAmounts] = useState<{ [key: string]: number }>({});
    const [qrCodeDataURL, setQrCodeDataURL] = useState<string>('');
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [selectedProductId, setSelectedProductId] = useState<string>('');
    const [sendSubmit, setSendSubmit] = useState(false);
    const [showMessenger, setShowMessenger] = useState(false);

    const [plusProductList, setPlusProductList] = useState<Array<any>>([]);
    const [upgradePriceMap, setUpgradePriceMap] = useState<{ [key: string]: number }>({});

    const [at, setAt] = useState<string>(accessData.accessToken || '');
    const [aid, setAid] = useState<string>(accessData.accountId || '');
    const [accountName, setAccountName] = useState<string>(accessData.account?.fullname || '');
    const [address, setAddress] = useState<string>('');
    const [addressAppend, setAddressAppend] = useState<string>('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isMobileApp, setIsMobileApp] = useState(false);
    const [locationSearchCompleted, setLocationSearchCompleted] = useState(false);
    const [parentPhone, setParentPhone] = useState<string>('');
    const [isProductExpanded, setIsProductExpanded] = useState(false);

    const currentPremium = transaction.transactionList.find((t) => t.status === '1');
    const confirmingPremium = transaction.transactionList.find((t) => t.status === '4');
    const selectedProduct = plusProductList.find(product => product.product_id === selectedProductId);
    const displayedProducts = isProductExpanded ? plusProductList : plusProductList.slice(0, 3);

    const currentDate = moment();
    const selectedLifetimeMonths = selectedProduct?.lifetime_of_product || 0;
    const selectedFutureDate = currentDate.clone().add(selectedLifetimeMonths, 'months');

    const addressMaxLength = 200;
    const userToken = generateToken(aid, at);
    const headers = { 'Accept': 'application/json', 'Content-Type': 'application/json', 'token': userToken };



    const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (address.length > addressMaxLength) { toast('กรุณากรอก ที่อยู่ ไม่เกิน 200 ตัวอักษร'); return; }
        if (!selectedFile) { toast('กรุณาอัปโหลดภาพสลิปโอนเงิน'); return; };
        setSendSubmit(true);
        try {
            if (selectedFile) {
                const { upload_url, file_id } = await getSlackFileUploadUrl(selectedFile.name, selectedFile.size);
                const formData = new FormData();
                formData.append('file', selectedFile);
                formData.append('uploadUrl', upload_url);
                await uploadFileToServer(formData);
                await completeSlackUpload(file_id, accountName, true, selectedProduct.description_th, selectedFutureDate);
            }
            await createPrePromptPay();
            await updateAddress();
            await updatePhoneNumber();
            await insertPendingSchoolRoom(aid);
            setShowMessenger(true);
            window.open('https://line.me/R/ti/p/%40nockacademye456', '_blank');

        } catch (error) {
            console.error("Error during verification/sign-in:", error);
            toast("พบปัญหาขณะสมัครใช้งาน กรุณาติดต่อ Admin");
        } finally {
            setSendSubmit(false);
        }
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) return;

        const maxSizeBytes = 5 * 1024 * 1024; // 5MB

        if (!file.type.startsWith('image/')) {
            toast('กรุณาอัปโหลดภาพสลิปโอนเงิน');
        } else if (file.size > maxSizeBytes) {
            toast('ขนาดไฟล์ใหญ่เกิน 5MB กรุณาอัปโหลดรูปที่มีขนาดเล็กกว่านี้');
        } else {
            setSelectedFile(file);
            const fileNameDisplay = document.getElementById("payMobileFileName");
            if (fileNameDisplay) fileNameDisplay.textContent = file.name;
            return;
        }

        event.target.value = '';
        setSelectedFile(null);
    };

    const getSlackFileUploadUrl = async (filename: string, length: number) => {
        const url = `${process.env.REACT_APP_API_URL_V2}/v1/slack/files/getUploadURLExternal`;
        const body = JSON.stringify({ filename, length });
        try {
            const response = await fetch(url, { method: 'POST', headers, body });
            if (!response.ok) throw new Error(`${response.statusText}`);
            return await response.json();
        } catch (error) {
            console.error(`Error: ${error}`);
            throw error;
        }
    };

    const uploadFileToServer = async (formData: FormData) => {
        const url = `${process.env.REACT_APP_API_URL_V2}/v1/slack/files/uploadExternal`;
        const headers: HeadersInit = { 'token': userToken };
        try {
            const response = await fetch(url, { method: 'POST', headers, body: formData });
            if (!response.ok) throw new Error(`${response.statusText}`);
            return await response.json();
        } catch (error) {
            console.error(`Error: ${error}`);
            throw error;
        }
    };

    const completeSlackUpload = async (file_id: string, name: string, isPremiumPlus: boolean, product: string, futureDate: moment.Moment) => {
        const url = `${process.env.REACT_APP_API_URL_V2}/v1/slack/files/completeUploadExternal`;
        const expireTime = futureDate.format('DD/MM/YYYY');
        const body = JSON.stringify({ file_id, name, isPremiumPlus, product, expireTime });
        try {
            const response = await fetch(url, { method: 'POST', headers, body });
            if (!response.ok) throw new Error(`${response.statusText}`);
            return await response.json();
        } catch (error) {
            console.error(`Error: ${error}`);
            throw error;
        }
    };

    const generateQrCode = async () => {
        const mobileNumber = '0205557003965';
        const payload = generatePayload(mobileNumber, { amount });
        const options: QRCodeToDataURLOptions = { color: { dark: '#003b6a', light: '#ffffff' } };
        try {
            const qrCodeDataURL = await qrcode.toDataURL(payload, options);
            setQrCodeDataURL(qrCodeDataURL);
        } catch (error) {
            console.error('Error generating QR code:', error);
        }
    };

    const updateAmount = () => {
        if (selectedLifetimeMonths > 0) {
            const description = selectedProduct?.description || "";
            const price = selectedProduct.price;
            const newAmount = calculatePrice({ price, description, lifetime_of_product: selectedLifetimeMonths });
            setAmount(newAmount);
        } else {
            setAmount(0);
        }
    };

    const updateAmounts = () => {
        const newAmounts: { [key: string]: number } = {};
        plusProductList.forEach((product) => {
            const { subscription_months, price, lifetime_of_product, product_id } = product;
            if (subscription_months > 0 && lifetime_of_product > 0 && price > 0) {
                const newAmount = calculatePrice(product);
                newAmounts[product_id] = newAmount;
            } else {
                newAmounts[product_id] = 0;
            }
        });
        setAmounts(newAmounts);
    };

    const calculatePrice = (product: ProductCalculate) => {
        const lifetimeMonths = product.lifetime_of_product || 0;
        const futureDate = currentDate.clone().add(lifetimeMonths, 'months');
        const totalDays = lifetimeMonths * 30;
        const dailyRate = Math.floor((product.price - 1100) / totalDays);

        if (lifetimeMonths > 0 && product.price > 0) {
            const matchedKey = Object.keys(upgradePriceMap).find(key => product.description.includes(key));
            const upgradePrice = matchedKey ? Number(upgradePriceMap[matchedKey]) : 0;

            if (currentPremium?.expire_time) {
                const expireTime = moment(currentPremium.expire_time);
                const isPaymentMethodAuto =
                    currentPremium.payment_method === "5" ||
                    currentPremium.payment_method === "6" ||
                    (currentPremium.payment_method === "7" && currentPremium.auto_renew === "1");
                if (!isPaymentMethodAuto && expireTime.isBefore(futureDate)) {
                    let totalAddition = Math.abs(expireTime.diff(currentDate, 'days')) + 1
                    totalAddition = (totalDays - Math.max(totalAddition, 0));
                    totalAddition = Math.min(totalAddition * dailyRate, 4700);
                    return Number(upgradePrice) + totalAddition;
                } else {
                    return Number(upgradePrice);
                }

            } else {
                return Number(product.price);
            }
        } else {
            return 0;
        }
    };

    const checkUserSchoolStatus = async () => {
        const url = `${process.env.REACT_APP_API_URL_V2}/v1/school/check-user?userId=${aid}`;
        try {
            const response = await fetch(url, { method: 'GET', headers });
            if (!response.ok) throw new Error(`${response.statusText}`);

            const result = await response.json();
            return result.exists;
        } catch (error) {
            console.error('Error fetching user school status:', error);
            return false;
        }
    };

    const checkAccessDataRedirect = async () => {
        const isUserInSchool = await checkUserSchoolStatus();

        const isNotAuthenticated = locationSearchCompleted && !isMobileApp && !(accessData && accessData.accessToken);
        const isPremiumUserInSchool = currentPremium && isUserInSchool;
        const isConfirmingPremiumAndInSchool = confirmingPremium && isUserInSchool;
        const isMobileAppWithAccessData = isMobileApp && (accessData && accessData.accessToken);

        if (isMobileAppWithAccessData) {
            window.location.href = '/pay-mobile-premium';
            return;
        }

        if (isNotAuthenticated || isPremiumUserInSchool || isConfirmingPremiumAndInSchool) {
            history.push('/');
            return;
        }
    };

    const changePlan = async (selectPlan: string) => {
        const product = plusProductList.find((t) => t.product_id == selectPlan);
        dispatch(reloadTransaction(at, aid));
        setAmount(parseInt(product.price));
        setSelectedProductId(selectPlan);
    };

    const insertPendingSchoolRoom = async (userId: string) => {
        const url = `${process.env.REACT_APP_API_URL_V2}/v1/school/school-room/insert/pending`;
        const body = JSON.stringify({ userId });
        const isUserInSchool = await checkUserSchoolStatus();
        if (!isUserInSchool) {
            try {
                const response = await fetch(url, { method: 'POST', headers, body, });
                if (!response.ok) throw new Error(`${response.statusText}`);
                return await response.json();
            } catch (error) {
                console.error('Error inserting pending school room:', error);
                throw error;
            }
        }
    };

    const createPrePromptPay = async () => {

        if (currentPremium && currentPremium.expire_time && moment(currentPremium.expire_time).isSameOrAfter(selectedFutureDate)) {
            console.warn("Early return: Current premium expires after future date.");
            return;
        }

        const apiUrl = `${process.env.REACT_APP_API_URL_V2}/v1/payment/create/pre/promptpay/amount`;
        const body = JSON.stringify({ userId: aid, productId: selectedProductId, amount, isPremiumPlus: true });

        try {
            const response = await fetch(apiUrl, { method: 'POST', headers, body });
            if (!response.ok) throw new Error(`${response.statusText}`);
        } catch (error) {
            console.error("Error creating pre-prompt pay:", error);
            throw error;
        }
    };

    const fetchPremiumPlus = async () => {
        const url = `${process.env.REACT_APP_API_URL_V2}/v1/payment/product/premium-plus`;
        try {
            const response = await fetch(url, { method: 'GET', headers });
            if (!response.ok) throw new Error(response.statusText);
            const result = await response.json();
            const lowestPriceProduct = result.data.sort((a: ProductPrice, b: ProductPrice) => a.price - b.price)[0];
            setPlusProductList([lowestPriceProduct]);
        } catch (error) {
            console.error("Error fetching premium plus products.");
        }
    };

    const updateAddress = async () => {
        if (!address) return;
        const fullAddress = `${address}, ${addressAppend}`;
        const url = `${process.env.REACT_APP_API_URL_V2}/v1/user/update/address`;
        const body = JSON.stringify({ userId: aid, address: fullAddress });
        try {
            const response = await fetch(url, { method: 'PUT', headers, body });
            if (!response.ok) throw new Error(`${response.statusText}`);
            return await response.json();
        } catch (error) {
            console.error('Error updating address:', error);
        }
    };

    const handleSelectAddress = (address: string) => setAddressAppend(address);

    const updatePhoneNumber = async () => {
        if (!parentPhone) return;
        const url = `${process.env.REACT_APP_API_URL_V2}/v1/user/update/parent-phone`;
        const body = JSON.stringify({ userId: aid, parentPhone });
        try {
            const response = await fetch(url, { method: 'PUT', headers, body });
            if (!response.ok) throw new Error(`${response.statusText}`);
            return await response.json();
        } catch (error) {
            console.error('Error updating parent phone:', error);
        }
    };

    const generateRandomString = () => { return Math.random().toString(36).substring(2, 10); };



    useEffect(() => {
        dispatch(reloadTransaction(at, aid));
        fetchPremiumPlus();
        const interval = setInterval(() => { dispatch(reloadTransaction(at, aid)) }, 6000);
        return () => clearInterval(interval);
    }, [aid, at]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        if (params.has('premiumPlus')) {
            const aidFromUrl = params.get('premiumPlus');
            if (aidFromUrl) {
                setIsMobileApp(true);
                setAid(aidFromUrl);
                setAt(aidFromUrl + generateRandomString());
                setAccountName(aidFromUrl);
            }
        }
        setLocationSearchCompleted(true);
    }, [location.search]);

    useEffect(() => {
        checkAccessDataRedirect();
    }, [accessData, currentPremium, confirmingPremium, locationSearchCompleted]);

    useEffect(() => { generateQrCode(); }, [amount]);

    useEffect(() => {
        if (productList.length > 0 && plusProductList.length > 0 && !selectedProductId) {
            setSelectedProductId(plusProductList[0].product_id);
            setAmount(parseInt(plusProductList[0].price));
        };
    }, [productList, selectedProductId]);

    useEffect(() => {
        if (plusProductList.length <= 0 || productList.length <= 0) return;

        const newPriceMap: { [key: string]: number } = {};
        const periods = ["(3 Months)", "(6 Months)"];

        periods.forEach(period => {
            const plusProduct = plusProductList.find(product => product.description.includes(period));
            const premiumProduct = productList.find(product => product.description.includes(period));

            if (plusProduct && premiumProduct) {
                const priceDifference = Number(plusProduct.price) - Number(premiumProduct.price);
                newPriceMap[period] = priceDifference;
            }
        });

        setUpgradePriceMap(newPriceMap);
    }, [plusProductList, productList]);

    useEffect(() => { if (selectedProductId) { updateAmount(); updateAmounts(); } }, [productList, selectedProductId]);



    if (productList.length === 0 || plusProductList.length === 0 || !selectedProductId || !amount) {
        return (
            <div className="mobile-pay-loading-container">
                <div className="mobile-pay-spinner"></div>
                <p>กำลังโหลด...</p>
            </div>
        );
    }

    return (
        <form onSubmit={handleFormSubmit}>
            <div className="mobile-pay-bg-sign-up">
                <div className="row">
                    <div className="mobile-pay-step-2 col-12">
                        <div className="mobile-pay-premium-text-payment col-12">
                            <div>เลือกคอร์สเรียน</div>

                            <div className="mobile-pay-product-selection">
                                {displayedProducts.map((product) => {
                                    const basePrice = amounts[product.product_id] || 0;
                                    const monthlyPrice = basePrice / product.subscription_months;
                                    const trimmedDescription = product.description_th.replace(/ Premium Plus$/, '');
                                    return (
                                        <div
                                            key={product.product_id}
                                            className={`mobile-pay-product-item ${selectedProductId === product.product_id ? 'selected' : ''}`}
                                            onClick={() => changePlan(product.product_id)}
                                        >
                                            <div className="mobile-pay-product-name">
                                                <label className="mobile-pay-product-radio">
                                                    <input
                                                        type="radio" name="product" value={product.product_id}
                                                        checked={selectedProductId === product.product_id} readOnly
                                                    />
                                                    {trimmedDescription}
                                                </label>
                                            </div>
                                            <div className="mobile-pay-product-pricing">
                                                <div className="mobile-pay-monthly-price">
                                                    ฿ {Math.ceil(monthlyPrice).toLocaleString(undefined, { maximumFractionDigits: 2 })} ต่อเดือน
                                                </div>
                                                <div className="mobile-pay-total-price">
                                                    ทั้งหมด ฿ {basePrice.toLocaleString()}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                                {plusProductList.length > 3 && (
                                    <div onClick={() => setIsProductExpanded(!isProductExpanded)} className="mobile-pay-view-all-button">
                                        {isProductExpanded ? 'ปิด' : 'ดูทั้งหมด'}
                                    </div>
                                )}
                            </div>

                            <div className="mobile-pay-checkbox-list">
                                <ul>
                                    {[
                                        "ไลฟ์สอนสดฟรี",
                                        "ไลฟ์สอนสดพรีเมียม",
                                        "ชมคลิปบทเรียนสั้น",
                                        "ชมคลิปติวสอบเข้าโรงเรียนดัง",
                                        <><span>รายงานผลการเรียน</span><br /><span>พร้อมคำแนะนำ</span></>,
                                        "หนังสือประกอบการเรียน",
                                    ].map((label, index) => (
                                        <li key={index}>
                                            <input type="checkbox" id={`mobile-pay-checkbox-${index + 1}`} checked readOnly />
                                            <label htmlFor={`mobile-pay-checkbox-${index + 1}`}>{label}</label>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                        </div>

                        <div className="mobile-pay-price-display col-12">
                            ยอดที่ต้องชำระ – {amount.toLocaleString()} บาท
                        </div>

                        {selectedProductId && (
                            <div className="mobile-pay-qr col-12">
                                <img id="promptpay-logo" src={promptPay} alt="PromptPay Logo" />
                                {qrCodeDataURL && <img src={qrCodeDataURL} alt={`QR Code for ${amount} baht`} />}
                                <p>หมายเลขอ้างอิง: 0-2055-57003-96-5</p>
                            </div>
                        )}

                        <div className="mobile-pay-address col-12 mt-4">
                            <input
                                type="text" id="addressAppend" value={addressAppend} readOnly required
                                onClick={() => setIsModalOpen(true)} className="form-control"
                                placeholder="เลือกจังหวัด อำเภอ ตำบล รหัสไปรษณีย์..."
                            />
                            <input
                                type="text" id="address" value={address} maxLength={addressMaxLength} required
                                onChange={(e) => setAddress(e.target.value)} className="form-control"
                                placeholder="กรอกที่อยู่ เลขที่บ้าน อาคาร ซอย..."
                            />
                            <input
                                type="text" id="parentPhone" value={parentPhone} maxLength={10} required
                                onChange={(e) => setParentPhone(e.target.value)} className="form-control"
                                placeholder="กรอกเบอร์โทร สำหรับติดต่อเพิ่มเติม..."
                            />
                            <AddressModal
                                isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} onSelect={handleSelectAddress}
                            />
                        </div>

                        <div className="mobile-pay-notes col-12">
                            <p>ชำระผ่าน Mobile Banking ได้โดย</p>
                            <ul className="mobile-pay-notes-list">
                                <li>Capture หน้าจอโทรศัพท์</li>
                                <li>นำภาพ QR ชำระผ่าน App ของธนาคาร</li>
                                <li>อัปโหลดสลิปหลักฐานการโอนเงินผ่านปุ่มด้านล่าง</li>
                                <li>ยอมรับเงื่อนไขและข้อตกลง แล้วกดสมัครใช้บริการ</li>
                                <li>แจ้งการชำระเงินกับแอดมินผ่านทาง <strong>LINE</strong></li>
                            </ul>
                        </div>

                        <div className="pay-mobile-image-upload col-12">
                            <input type="file" id="fileUpload" onChange={handleFileChange} />
                            <label htmlFor="fileUpload">
                                อัปโหลดสลิป
                                <img src={uploadIcon} alt="Upload" className="pay-mobile-upload-icon" />
                            </label>
                            <span id="payMobileFileName"></span>
                        </div>

                        <div className="mobile-pay-requirements col-12 mt-4 d-flex align-items-center">
                            <p>ข้าพเจ้ายอมรับ</p>
                            <p>
                                <label>
                                    <a target="_blank" rel="noopener noreferrer" href="https://nockacademy.com/index.php/terms-and-conditions">เงื่อนไข </a>
                                    และ
                                    <a target="_blank" rel="noopener noreferrer" href="https://nockacademy.com/index.php/privacy-and-cookie-policy"> ข้อตกลงการใช้บริการ</a>
                                </label>
                                <span style={{ marginLeft: "10px" }}></span>
                                <input className="mobile-pay-accept mr-2" type="checkbox" id="accept-terms" required />
                            </p>
                        </div>
                    </div>

                    {!showMessenger && (
                        <div className="col-12 mobile-pay-register-button-container">
                            <button id="mobile-pay-register-button" className="m-auto" type="submit" disabled={sendSubmit}>
                                สมัครใช้บริการ
                                <img style={{ maxHeight: "8vh" }} src={loading} hidden={!sendSubmit} />
                            </button>
                        </div>
                    )}

                    {showMessenger && (
                        <div className="pay-mobile-messenger-container col-12">
                            <a
                                href={`https://line.me/R/ti/p/%40nockacademye456`}
                                className="pay-mobile-line-us-button col-12"
                                target="_blank"
                                onClick={() => history.push('/')}
                            >
                                <img src={lineIcon} alt="LINE Icon" className="msg-icon" />
                                แจ้งการชำระเงิน
                            </a>
                        </div>
                    )}

                </div>
            </div>

            <ToastContainer
                position="top-center" autoClose={3000} hideProgressBar newestOnTop
                closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover
            />
        </form >
    );
};

export default PayMobilePremium;
