import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { generateToken } from '../../utility/Token';
import qrcode from 'qrcode';
import generatePayload from 'promptpay-qr';
import moment from 'moment';
import { QRCodeToDataURLOptions } from 'qrcode';
import { reloadTransaction } from '../../stores/transaction/Transaction';
import { toast, ToastContainer } from 'react-toastify';

import './PayExtensionPremium.css';
import lineIcon from '../../images/other/line.svg'
import loading from '../../images/loading/loading_spinner.svg';
import promptPay from '../../images/bank/PromptPay2.png';
import uploadIcon from '../../images/icon/upload-file-white.svg';

interface Product { price: number; }

interface ProductCalculate { price: number; lifetime_of_product: number; description: String; }



const PayExtensionPremium = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const accessData = useSelector((state: NaTypes.Store) => state.authenticate);
    const productList = useSelector((state: NaTypes.Store) => state.transaction.productList);
    const transaction = useSelector((state: NaTypes.Store) => state.transaction);

    const [sendSubmit, setSendSubmit] = useState(false);
    const [showMessenger, setShowMessenger] = useState(false);

    const [isProductExpanded, setIsProductExpanded] = useState(false);

    const [amount, setAmount] = useState<number>(0);
    const [amounts, setAmounts] = useState<{ [key: string]: number }>({});
    const [qrCodeDataURL, setQrCodeDataURL] = useState<string>('');
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [selectedProductId, setSelectedProductId] = useState<string>('');
    const [plusExpireDate, setPlusExpireDate] = useState<moment.Moment>(moment(''));


    const filteredProducts = productList.filter(product => product.description === "Subscription (3 Months)");
    const selectedProduct = filteredProducts.find(product => product.product_id === selectedProductId);
    const selectedProductRange = selectedProduct ? selectedProduct.subscription_months : '';
    const selectedLifetimeMonths = selectedProduct?.lifetime_of_product || 0;

    const currentPremium = transaction.transactionList.find((t) => t.status === '1');
    const extendStart = moment(currentPremium?.expire_time || '');
    const extendEnd = extendStart.clone().add(parseInt(selectedProductRange, 10), 'months');

    const lifetimeMonths = selectedProduct?.lifetime_of_product || 0;
    const currentDate = moment();
    const futureDate = currentDate.clone().add(lifetimeMonths, 'months');

    const at = accessData.accessToken ? accessData.accessToken : '';
    const aid = accessData.accountId ? accessData.accountId : '';
    const accountName = accessData.account ? accessData.account.fullname : 'User';
    const userToken = generateToken(aid, at);
    const headers = { 'Accept': 'application/json', 'Content-Type': 'application/json', 'token': userToken };

    const currentPremiumAndPending = transaction.transactionList.filter(
        (t) => t.status === '1' || t.status === '4'
    );
    const hasBothStatuses = currentPremiumAndPending.some(t => t.status === '1') && currentPremiumAndPending.some(t => t.status === '4');



    const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!selectedFile) { toast('กรุณาอัปโหลดภาพสลิปโอนเงิน'); return; };
        setSendSubmit(true);
        try {
            if (selectedFile) {
                const { upload_url, file_id } = await getSlackFileUploadUrl(selectedFile.name, selectedFile.size);
                const formData = new FormData();
                formData.append('file', selectedFile);
                formData.append('uploadUrl', upload_url);
                await uploadFileToServer(formData);
                await completeSlackUpload(file_id, accountName, true, selectedProduct.description_th, futureDate);
            }
            await createPrePromptPay();
            setShowMessenger(true);
            window.open('https://line.me/R/ti/p/%40nockacademye456', '_blank');

        } catch (error) {
            console.error("Error during verification/sign-in:", error);
            toast("พบปัญหาขณะสมัครใช้งาน กรุณาติดต่อ Admin");
        } finally {
            setSendSubmit(false);
        }
    };

    const createPrePromptPay = async () => {

        const apiUrl = `${process.env.REACT_APP_API_URL_V2}/v1/payment/create/pre/promptpay/amount`;
        const body = JSON.stringify({ userId: aid, productId: selectedProductId, amount, isPremiumPlus: true, extendStart, extendEnd });

        try {
            const response = await fetch(apiUrl, { method: 'POST', headers, body });
            if (!response.ok) throw new Error(`${response.statusText}`);
        } catch (error) {
            console.error("Error creating pre-prompt pay:", error);
            throw error;
        }
    };

    const changePlan = async (selectPlan: string) => {
        const product = filteredProducts.find((t) => t.product_id == selectPlan);
        dispatch(reloadTransaction(at, aid));
        setAmount(parseInt(product.price));
        setSelectedProductId(selectPlan);
    };

    const updateAmount = () => {
        if (selectedLifetimeMonths > 0) {
            const description = selectedProduct?.description || "";
            const price = parseInt(selectedProduct.price, 10);
            const newAmount = calculatePrice({ price, description, lifetime_of_product: selectedLifetimeMonths });
            setAmount(newAmount);
        } else {
            setAmount(0);
        }
    };

    const updateAmounts = () => {
        const newAmounts: { [key: string]: number } = {};
        filteredProducts.forEach((product) => {
            const { subscription_months, price, lifetime_of_product, product_id } = product;
            if (subscription_months > 0 && lifetime_of_product > 0 && price > 0) {
                const newAmount = calculatePrice(product);
                newAmounts[product_id] = newAmount;
            } else {
                newAmounts[product_id] = 0;
            }
        });
        setAmounts(newAmounts);
    };

    const calculatePrice = (product: ProductCalculate) => {
        const lifetimeMonths = product.lifetime_of_product || 0;
        const totalDays = lifetimeMonths * 30;
        const dailyRate = Math.floor(2800 / totalDays);

        const expireTime = moment(currentPremium.expire_time);
        if (plusExpireDate.isSame(expireTime, 'day')) return product.price;

        const daysUntilExpire = Math.min(Math.abs(plusExpireDate.diff(currentPremium.expire_time, 'days')), 90);
        let remainingPrice = product.price;
        const existingDeduction = Math.round(Math.max(daysUntilExpire, 0) * (dailyRate));
        remainingPrice = product.price - existingDeduction;

        const finalPrice = Math.max(product.price, 0);
        return finalPrice;
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) return;

        const maxSizeBytes = 5 * 1024 * 1024; // 5MB

        if (!file.type.startsWith('image/')) {
            toast('กรุณาอัปโหลดภาพสลิปโอนเงิน');
        } else if (file.size > maxSizeBytes) {
            toast('ขนาดไฟล์ใหญ่เกิน 5MB กรุณาอัปโหลดรูปที่มีขนาดเล็กกว่านี้');
        } else {
            setSelectedFile(file);
            const fileNameDisplay = document.getElementById("payMobileFileName");
            if (fileNameDisplay) fileNameDisplay.textContent = file.name;
            return;
        }
        event.target.value = '';
        setSelectedFile(null);
    };

    const getSlackFileUploadUrl = async (filename: string, length: number) => {
        const apiUrl = `${process.env.REACT_APP_API_URL_V2}/v1/slack/files/getUploadURLExternal`;
        const body = JSON.stringify({ filename, length });
        try {
            const response = await fetch(apiUrl, { method: 'POST', headers, body });
            if (!response.ok) throw new Error(`${response.statusText}`);
            return await response.json();
        } catch (error) {
            console.error(`Error: ${error}`);
            throw error;
        }
    };

    const uploadFileToServer = async (formData: FormData) => {
        const apiUrl = `${process.env.REACT_APP_API_URL_V2}/v1/slack/files/uploadExternal`;
        const headers: HeadersInit = { 'token': userToken };
        try {
            const response = await fetch(apiUrl, { method: 'POST', headers, body: formData });
            if (!response.ok) throw new Error(`${response.statusText}`);
            return await response.json();
        } catch (error) {
            console.error(`Error: ${error}`);
            throw error;
        }
    };

    const completeSlackUpload = async (file_id: string, name: string, isPremiumPlus: boolean, product: string, futureDate: moment.Moment) => {
        const url = `${process.env.REACT_APP_API_URL_V2}/v1/slack/files/completeUploadExternal`;
        const expireTime = futureDate.format('DD/MM/YYYY');
        const isPremiumPlusValue = isPremiumPlus ? isPremiumPlus : null;
        const extendedProduct = `${product} (Extension)`;
        const expireTimeValue = isPremiumPlus ? expireTime : null;
        const body = JSON.stringify({ file_id, name, isPremiumPlus: isPremiumPlusValue, product: extendedProduct, expireTime: expireTimeValue });
        try {
            const response = await fetch(url, { method: 'POST', headers, body });
            if (!response.ok) throw new Error(`${response.statusText}`);
            return await response.json();
        } catch (error) {
            console.error(`Error: ${error}`);
            throw error;
        }
    };

    const generateQrCode = async () => {
        const mobileNumber = '0205557003965';
        const payload = generatePayload(mobileNumber, { amount: amount });
        const options: QRCodeToDataURLOptions = { color: { dark: '#003b6a', light: '#ffffff' } };
        try {
            const qrCodeDataURL = await qrcode.toDataURL(payload, options);
            setQrCodeDataURL(qrCodeDataURL);
        } catch (error) {
            console.error('Error generating QR code:', error);
        }
    };

    const checkUserSchoolStatus = async () => {
        const url = `${process.env.REACT_APP_API_URL_V2}/v1/school/check-user?userId=${aid}`;
        try {
            const response = await fetch(url, { method: 'GET', headers });
            const result = response.ok ? await response.json() : Promise.reject(response.statusText);
            setPlusExpireDate(moment(result.expireDate));
        } catch (error) {
            console.error('Error fetching user school status:', error);
        }
    };

    const checkRedirect = async () => {
        const isNotUser = !accessData.accessToken;
        const isNotPremium = !transaction.isPremium;
        const isAutoPremium = currentPremium && ["5", "6", "7"].includes(currentPremium.payment_method);

        if (isNotUser || isNotPremium || isAutoPremium || hasBothStatuses) window.location.href = '/';
    };



    useEffect(() => {
        dispatch(reloadTransaction(at, aid));
        checkUserSchoolStatus();
    }, []);

    useEffect(() => { generateQrCode() }, [amount]);

    useEffect(() => {
        if (productList.length > 0) checkRedirect();
    }, [productList]);

    useEffect(() => {
        if (!selectedProductId) {
            const firstProduct = filteredProducts[0];
            if (firstProduct && (filteredProducts.length > 0)) changePlan(firstProduct.product_id);
        }
    }, [productList]);

    useEffect(() => { if (selectedProductId) { updateAmount(); updateAmounts(); } }, [productList, selectedProductId, plusExpireDate]);



    if (productList.length === 0 || !selectedProductId || !amount) {
        return (
            <div className="mobile-pay-loading-container">
                <div className="mobile-pay-spinner"></div>
                <p>กำลังโหลด...</p>
            </div>
        );
    }

    return (
        <form onSubmit={handleFormSubmit}>
            <div className="mobile-pay-bg-sign-up">
                <div className="row">

                    <div className="mobile-pay-header col-12 pt-4"><h3>ต่ออายุการใช้งานบริการ</h3></div>

                    <div className="mobile-pay-step-2 col-12">
                        <div className="mobile-pay-premium-text-payment col-12">
                            <div>เลือกคอร์สเรียน</div>

                            <div className="mobile-pay-product-selection">
                                {filteredProducts.map((product) => {
                                    const basePrice = amounts[product.product_id] || 0;
                                    const monthlyPrice = basePrice / product.subscription_months;
                                    const trimmedDescription = "ต่อ Premium 3 เดือน";
                                    return (
                                        <div
                                            key={product.product_id}
                                            className={`mobile-pay-product-item ${selectedProductId === product.product_id ? 'selected' : ''}`}
                                            onClick={() => changePlan(product.product_id)}
                                        >
                                            <div className="mobile-pay-product-name">
                                                <label className="mobile-pay-product-radio">
                                                    <input
                                                        type="radio" name="product" value={product.product_id}
                                                        checked={selectedProductId === product.product_id} readOnly
                                                    />
                                                    {trimmedDescription}
                                                </label>
                                            </div>
                                            <div className="mobile-pay-product-pricing">
                                                <div className="mobile-pay-monthly-price">
                                                    ฿ {Math.ceil(monthlyPrice).toLocaleString(undefined, { maximumFractionDigits: 2 })} ต่อเดือน
                                                </div>
                                                <div className="mobile-pay-total-price">
                                                    ทั้งหมด ฿ {basePrice.toLocaleString()}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                                {filteredProducts.length > 3 && (
                                    <div onClick={() => setIsProductExpanded(!isProductExpanded)} className="mobile-pay-view-all-button">
                                        {isProductExpanded ? 'ปิด' : 'ดูทั้งหมด'}
                                    </div>
                                )}
                            </div>

                            {currentPremium && selectedProduct && extendStart < extendEnd && (
                                <div className="mobile-pay-current-premium col-12">
                                    <div>{extendStart.format('DD MMMM, YYYY')} – {extendEnd.format('DD MMMM, YYYY')}</div>
                                </div>
                            )}

                        </div>

                        <div className="mobile-pay-price-display col-12">
                            ยอดที่ต้องชำระ – {amount.toLocaleString()} บาท
                        </div>

                        {selectedProductId && (
                            <div className="mobile-pay-qr col-12">
                                <img id="promptpay-logo" src={promptPay} alt="PromptPay Logo" />
                                {qrCodeDataURL && <img src={qrCodeDataURL} alt={`QR Code for ${amount} baht`} />}
                                <p>หมายเลขอ้างอิง: 0-2055-57003-96-5</p>
                            </div>
                        )}

                        <div className="mobile-pay-notes col-12">
                            <p>ชำระผ่าน Mobile Banking ได้โดย</p>
                            <ul className="mobile-pay-notes-list">
                                <li>Capture หน้าจอโทรศัพท์</li>
                                <li>นำภาพ QR ชำระผ่าน App ของธนาคาร</li>
                                <li>อัปโหลดสลิปหลักฐานการโอนเงินผ่านปุ่มด้านล่าง</li>
                                <li>ยอมรับเงื่อนไขและข้อตกลง แล้วกดสมัครใช้บริการ</li>
                                <li>แจ้งการชำระเงินกับแอดมินผ่านทาง Messenger</li>
                            </ul>
                        </div>

                        <div className="pay-mobile-image-upload col-12">
                            <input type="file" id="fileUpload" onChange={handleFileChange} />
                            <label htmlFor="fileUpload">
                                อัปโหลดสลิป
                                <img src={uploadIcon} alt="Upload" className="pay-mobile-upload-icon" />
                            </label>
                            <span id="payMobileFileName"></span>
                        </div>

                        <div className="mobile-pay-requirements col-12 mt-4 d-flex align-items-center">
                            <p>ข้าพเจ้ายอมรับ</p>
                            <p>
                                <label>
                                    <a target="_blank" rel="noopener noreferrer" href="https://nockacademy.com/index.php/terms-and-conditions">เงื่อนไข </a>
                                    และ
                                    <a target="_blank" rel="noopener noreferrer" href="https://nockacademy.com/index.php/privacy-and-cookie-policy"> ข้อตกลงการใช้บริการ</a>
                                </label>
                                <span style={{ marginLeft: "10px" }}></span>
                                <input className="mobile-pay-accept mr-2" type="checkbox" id="accept-terms" required />
                            </p>
                        </div>
                    </div>

                    {!showMessenger && (
                        <div className="col-12 mobile-pay-register-button-container">
                            <button id="mobile-pay-register-button" className="m-auto" type="submit" disabled={sendSubmit}>
                                ต่ออายุ
                                <img style={{ maxHeight: "8vh" }} src={loading} hidden={!sendSubmit} />
                            </button>
                        </div>
                    )}

                    {showMessenger && (
                        <div className="pay-mobile-messenger-container col-12">
                            <a
                                href={`https://line.me/R/ti/p/%40nockacademye456`}
                                className="pay-mobile-line-us-button col-12"
                                target="_blank"
                                onClick={() => history.push('/')}
                            >
                                <img src={lineIcon} alt="LINE Icon" className="msg-icon" />
                                แจ้งการชำระเงิน
                            </a>
                        </div>
                    )}

                </div>
            </div>

            <ToastContainer
                position="top-center" autoClose={3000} hideProgressBar newestOnTop
                closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover
            />
        </form >
    );
};

export default PayExtensionPremium;
