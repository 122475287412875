import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

interface JoinMessageProps {
    message: MessageItem;
    open: boolean;
    anchorEl: HTMLDivElement | HTMLButtonElement | null;
    handleClick: (event: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => void;
    handleClose: () => void;
    handleSelect: (value: string, key: string, userId: string) => void;
    isAdmin: boolean;
    blurForUser: boolean;
}


const JoinMessage: React.FC<JoinMessageProps> = ({ message, blurForUser, isAdmin, open, anchorEl, handleClick, handleClose, handleSelect }) => {
    const options = [
        { value: 'delete', text: 'ลบ' },
    ];

    const adminOptions = [
        { value: 'delete', text: 'ลบ' },
        { value: 'ban', text: 'แบน' },
    ];

    return (
        <div
            className={`chat-message-joined no-select ${blurForUser ? 'chat-message-item-blur' : ''}`}
            onClick={isAdmin ? handleClick : undefined}
            style={{ cursor: isAdmin ? 'pointer' : 'default' }}
        >
            {message.userName} joined

            {(isAdmin || message.self || message.admin) && (
                <div className="chat-message-item-three-dots-menu-container">
                    <Menu
                        id="long-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        getContentAnchorEl={null}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        open={open}
                        onClose={handleClose}
                        PaperProps={{ style: { maxHeight: 48 * 4.5, width: '10ch' } }}
                    >
                        {message.self
                            ? options.map((option) => (
                                <MenuItem
                                    key={option.value}
                                    value={option.value}
                                    onClick={() => { handleClose(); handleSelect(option.value, message.key, message.userId); }}
                                >
                                    {option.text}
                                </MenuItem>
                            ))
                            : message.admin
                                ? adminOptions.map((option) => (
                                    <MenuItem
                                        key={option.value}
                                        value={option.value}
                                        onClick={() => { handleClose(); handleSelect(option.value, message.key, message.userId); }}
                                    >
                                        {option.text}
                                    </MenuItem>
                                ))
                                : null}
                    </Menu>
                </div>
            )}
        </div>
    );
};

export default JoinMessage;
