import { Dispatch } from 'redux';
import { generateToken } from '../../utility/Token';
import { MixpanelTracking } from '../../utility/MixpanelTracking';
import moment from 'moment';
import { clearAccessData } from '../../stores/authorization/AuthorizationState';
import { resetLessonSet } from '../../stores/lesson-list/LessonSetListState';

const initialState: Transaction = {
    pending: false,
    transactionList: [],
    isPremium: false,
    waitingPayment: false,
    requestPremium: false,
    productList: []
};

const FETCH_TRANSACTION_LIST_PENDING = 'FETCH_TRANSACTION_LIST_PENDING';
const fetchTransactionListPending = () => {
    return {
        type: FETCH_TRANSACTION_LIST_PENDING,
    };
};

const FETCH_TRANSACTION_LIST_SUCCESS = 'FETCH_TRANSACTION_LIST_SUCCESS';
const fetchTransactionListSuccess = (data: any, list: any) => {
    return {
        type: FETCH_TRANSACTION_LIST_SUCCESS,
        payload: data,
        productlist: list,
    };
};

const REQUEST_PREMIUM = 'REQUEST_PREMIUM';
const requestPremium = () => {
    return {
        type: REQUEST_PREMIUM,
    };
};

const CANCEL_REQUEST_PREMIUM = 'CANCEL_REQUEST_PREMIUM';
const cancelRequestPremium = () => {
    return {
        type: CANCEL_REQUEST_PREMIUM,
    };
};



const reloadTransaction = (accessToken: string, accountId: string) => async (dispatch: Dispatch<any>) => {
    try {
        const url = `${process.env.REACT_APP_API_URL}/api/v4/method/paymentSystem?access_token=${generateToken(accountId, accessToken)}&accountId=${accountId}&all=true`;
        dispatch(fetchTransactionListPending());
        const response = await fetch(url, {
            method: 'GET',
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const res = await response.json();

        if (res.error_code && res.error_code === 101) {
            dispatch(clearAccessData());
            dispatch(resetLessonSet());
            window.location.reload();
        }

        const { purchase_transactions, product_list } = res;
        const transactions = purchase_transactions || [];
        const products = product_list || [];

        dispatch(fetchTransactionListSuccess(transactions, products));
    } catch (error) {
        console.error('Error occurred:', error);
    }
};



export {
    requestPremium,
    cancelRequestPremium,
    fetchTransactionListPending,
    fetchTransactionListSuccess,
    reloadTransaction,
};

/**
 * Transaction state
 * @param {Transaction} state
 * @param {any} action
 * @return {Transaction} current state
 */
export function TransactionState(
    state: Transaction = initialState,
    action: any): Transaction {
    switch (action.type) {
        case FETCH_TRANSACTION_LIST_PENDING:
            return Object.assign({}, state, {
                pending: true,
            });
        case FETCH_TRANSACTION_LIST_SUCCESS:
            const find = action.payload.find((t: any) => moment(t.expire_time).utc() > moment.utc(moment.now()) && (t.status === '1' || t.status === '4'));
            const findWaiting = action.payload.find((t: any) => moment(t.expire_time).utc() > moment.utc(moment.now()) && (t.status === '5'));
            let isPremium: boolean = false;
            let waitingPayment: boolean = false;
            if (find) {
                isPremium = true;
            }
            if (findWaiting) {
                isPremium = false;
                waitingPayment = true;
            }
            MixpanelTracking.setPlanType(isPremium);
            return Object.assign({}, state, {
                pending: false,
                isPremium,
                waitingPayment,
                transactionList: action.payload,
                productList: action.productlist
            });
        case REQUEST_PREMIUM:
            return Object.assign({}, state, {
                requestPremium: true,
            });
        case CANCEL_REQUEST_PREMIUM:
            return Object.assign({}, state, {
                requestPremium: false,
            });
        default:
            return state;
    }
};
