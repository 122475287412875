import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { generateToken } from '../../utility/Token';
import { toast } from 'react-toastify';
import JoinMessage from './JoinMessage';
import ChatMessage from './ChatMessage';

interface MessageProps { message: MessageItem; deleteMessage: (key: string) => void; blur: boolean }



const Message: React.FC<MessageProps> = ({ message, deleteMessage, blur }) => {
    const accessData = useSelector((state: NaTypes.Store) => state.authenticate);
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | HTMLButtonElement | null>(null);
    const [randomText, setRandomText] = useState<string>('');

    const aid = accessData.accountId ? accessData.accountId : '';
    const isAdmin = aid === process.env.REACT_APP_ADMIN_ACCOUNT;
    const blurForUser = blur && !isAdmin;
    const open = Boolean(anchorEl);



    const handleClick = (event: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
        !open ? setAnchorEl(event.currentTarget) : handleClose();
    };

    const handleSelect = (value: string, key: string, userId: string) => {
        switch (value) {
            case 'delete':
                deleteMessage(key);
                break;
            case 'ban':
                banUser(userId);
                break;
        }
    };

    const handleClose = () => setAnchorEl(null);

    const banUser = async (userId: string) => {
        if (!accessData.accessToken) return;
        const token = generateToken(aid, accessData.accessToken!);
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL_V2}/v1/user/ban`, {
                method: 'POST',
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'token': token },
                body: JSON.stringify({ user_id: userId })
            });
            if (response.ok) {
                toast(`Ban user_id: ${userId}. Don't forget to unban later.`);
            } else {
                console.log(`Error banning user_id: ${userId}`);
            }
        } catch (err) {
            console.error(err);
        }
    };

    const generateRandomText = (text: string): string =>
        text.replace(/\S/g, () => 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.charAt(Math.random() * 52));



    useEffect(() => {
        if (blurForUser && message.message) setRandomText(generateRandomText(message.message));
    }, [blurForUser, message.message]);



    const messageText = blurForUser ? randomText : message.message;

    return (
        <>
            {message.type === "JOIN" ? (
                <JoinMessage
                    message={message}
                    open={open}
                    anchorEl={anchorEl}
                    handleClick={handleClick}
                    handleClose={handleClose}
                    handleSelect={handleSelect}
                    isAdmin={isAdmin}
                    blurForUser={blurForUser}
                />
            ) : (
                <ChatMessage
                    message={message}
                    open={open}
                    anchorEl={anchorEl}
                    handleClick={handleClick}
                    handleClose={handleClose}
                    handleSelect={handleSelect}
                    messageText={messageText}
                    blurForUser={blurForUser}
                />
            )}
        </>
    );
};

export default Message;
